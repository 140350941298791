/* eslint-disable */
export const lightTheme = {
  background: "#d3dee9",
  color: "#042c44",
  accentColor: "#3D84C6",
  chronoTheme: {
    cardBgColor: "white",
    cardForeColor: "black",
    titleColor: "white",
  },
  timelineLineColor: "#042c44",
  cardBackground: "#d3dee9",
  cardFooterBackground: "#6494c4",
  cardBorderColor: "#73b8e1",
  navbarTheme: {
    linkColor: "#d3dee9",
    linkHoverColor: "#73b8e1",
    linkActiveColor: "#73b8e1",
  },
  bsPrimaryVariant: "light",
  bsSecondaryVariant: "dark",
  socialIconBgColor: "#042c44",
};

export const darkTheme = {
  background: "#042c44",
  color: "#d3dee9",
  accentColor: "#3D84C6",
  chronoTheme: {
    cardBgColor: "#072530",
    cardForeColor: "#d3dee9",
    titleColor: "black",
  },
  timelineLineColor: "#d3dee9",
  cardBackground: "#042c44",
  cardFooterBackground: "#445484",
  cardBorderColor: "#6494c4",
  navbarTheme: {
    linkColor: "#d3dee9",
    linkHoverColor: "#73b8e1",
    linkActiveColor: "#73b8e1",
  },
  bsPrimaryVariant: "dark",
  bsSecondaryVariant: "light",
  socialIconBgColor: "#d3dee9",
};
